import { useRef, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Product.css';

import businessBrochure from '../images/products/business-brochure.jpg';
import businessBooklet from '../images/products/business-booklet.jpg';
import businessCard from '../images/products/business-card.jpg';
import businessIdcard from '../images/products/business-idcard.jpg';


function Business() {
  const productRef = useRef(null)

  const scrollToBottom = () => {
    productRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (localStorage.getItem('navClick')) {
      scrollToBottom();
      localStorage.removeItem('navClick');
    }
  }, []);
  return (
    <div className="Product-container">
      <Container fluid="md">
        <Row className="Product-row">
          <Col className="Product-item">
            <div className="Product-image">
              <img src={businessBrochure} alt='Brochure' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Brochure</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          <Col className="Product-item" ref={productRef}>
            <div className="Product-image">
              <img src={businessCard} alt='Business Card' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Business Card</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          {/* <Col className="Product-item">
            <div className="Product-image">
              <img src={businessFolder} alt='Business Folder' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Business Folder</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col> */}
        </Row>
        <Row>
          <Col className="Product-item">
            <div className="Product-image">
              <img src={businessIdcard} alt='ID Card' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>ID Card</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          <Col className="Product-item">
            <div className="Product-image">
              <img src={businessBooklet} alt='Booklet' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Booklet</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
        </Row>
        <Row>
          {/* <Col className="Product-item">
            <div className="Product-image">
              <img src={businessLetterHead} alt='Letter Head' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Letter Head</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col> */}
          {/* <Col className="Product-item">
            <div className="Product-image">
              <img src={businessMenu} alt='Menu' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Menu</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
}

export default Business;
