import { useEffect, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Product.css';

import banner from '../images/products/banner.jpg';
import bannerRollup from '../images/products/banner-rollup.jpg';
import bannerX from '../images/products/banner-x.jpg';


function Banner() {
  const productRef = useRef(null)

  const scrollToBottom = () => {
    productRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (localStorage.getItem('navClick')) {
      scrollToBottom();
      localStorage.removeItem('navClick');
    }
  }, []);

  return (
    <div className="Product-container">
      <Container fluid="md">
        <Row>
          <Col className="Product-item">
            <div className="Product-image">
              <img src={banner} alt='Banner' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Banner</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          <Col className="Product-item" ref={productRef}>
            <div className="Product-image">
              <img src={bannerRollup} alt='Rollup' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Roll Up Banner</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          <Col className="Product-item">
            <div className="Product-image">
              <img src={bannerX} alt='X-Banner' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>X-Banner</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Banner;
