import { useRef, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Product.css';

import partyInvitation from '../images/products/party-invitation.jpg';
import partySupplies from '../images/products/party-supplies.jpg';


function Party() {
  const productRef = useRef(null)

  const scrollToBottom = () => {
    productRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (localStorage.getItem('navClick')) {
      scrollToBottom();
      localStorage.removeItem('navClick');
    }
  }, []);
  return (
    <div className="Product-container">
      <Container fluid="md">
        {/* <Row className="Product-row">
          <Col className="Product-item" ref={productRef}>
            <div className="Product-image">
              <img src={partyAngpao} alt='Angpao' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Angpao</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          <Col className="Product-item">
            <div className="Product-image">
              <img src={partyFlagChain} alt='Flag Chain' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Flag Chain</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          <Col className="Product-item">
            <div className="Product-image">
              <img src={partyHampers} alt='Hampers' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Hampers</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col className="Product-item" ref={productRef}>
            <div className="Product-image">
              <img src={partyInvitation} alt='Party Invitation' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Party Invitation</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          <Col className="Product-item">
            <div className="Product-image">
              <img src={partySupplies} alt='Party Supplies' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Party Supplies</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col>
          {/* <Col className="Product-item">
            <div className="Product-image">
              <img src={partyTableMat} alt='Party Table Mat' className="Product-image-size" />
            </div>
            <div className="Product-text">
              <b>Party Table Mat</b><br />
              <Button variant='primary' href="https://wa.me/08992846138">Call for Price</Button>
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
}

export default Party;
